export const workItems = [
  {
    heading: 'heading-1',
    listItems: [
      'list-item-1-1',
      'list-item-1-2',
      'list-item-1-3',
      'list-item-1-4',
      'list-item-1-5'
    ]
  },
  {
    heading: 'heading-2',
    listItems: [
      'list-item-2-1',
      'list-item-2-2',
      'list-item-2-3',
      'list-item-2-4'
    ]
  },
  {
    heading: 'heading-3',
    listItems: ['list-item-3-1', 'list-item-3-2']
  }
];
