import React from 'react';

export const PrevArrowIcon = props => (
  <svg
    width={32}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.904 9.533H2.528l1.054.883 7.193 6.025a.39.39 0 0 1 .156.294.39.39 0 0 1-.156.295.767.767 0 0 1-.488.164.767.767 0 0 1-.488-.164L.657 9.37a.467.467 0 0 1-.122-.15.325.325 0 0 1-.035-.145c0-.046.01-.095.035-.145a.467.467 0 0 1 .121-.149L9.8 1.12a.767.767 0 0 1 .488-.164c.197 0 .371.066.488.164a.39.39 0 0 1 .156.294.39.39 0 0 1-.156.295L3.582 7.735l-1.054.883h28.329c.197 0 .37.066.487.164a.389.389 0 0 1 .156.293c0 .09-.042.199-.156.294a.765.765 0 0 1-.487.164H3.904Z"
      fill="#74B88D"
      stroke="#74B88D"
    />
  </svg>
);

export const NextArrowIcon = props => (
  <svg
    width={32}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.096 8.618h1.376l-1.054-.883-7.193-6.025a.39.39 0 0 1-.156-.295.39.39 0 0 1 .156-.294.767.767 0 0 1 .488-.164c.197 0 .371.066.488.164l9.142 7.66c.058.048.097.1.122.15.024.049.035.098.035.144 0 .047-.01.096-.035.145a.468.468 0 0 1-.121.15L22.2 17.03a.767.767 0 0 1-.488.164.767.767 0 0 1-.488-.164.39.39 0 0 1-.156-.295.39.39 0 0 1 .156-.294l7.193-6.025 1.054-.883H1.143a.765.765 0 0 1-.487-.164C.542 9.274.5 9.165.5 9.075s.042-.198.156-.293a.765.765 0 0 1 .487-.164h26.954Z"
      stroke="#74B88D"
    />
  </svg>
);

export const CloseIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path d="M4.707 3.293 3.293 4.707 10.586 12l-7.293 7.293 1.414 1.414L12 13.414l7.293 7.293 1.414-1.414L13.414 12l7.293-7.293-1.414-1.414L12 10.586 4.707 3.293z" />
  </svg>
);

export const PhoneIcon = props => (
  <svg
    width={26}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.334 17.844 2.24-2.068a2.251 2.251 0 0 1 1.098-.541 2.36 2.36 0 0 1 1.24.099l2.729 1.006c.398.149.74.404.982.733.242.328.373.715.377 1.113V22.8c-.002.27-.064.538-.18.785a2.011 2.011 0 0 1-.5.657c-.214.185-.465.329-.74.422a2.334 2.334 0 0 1-.859.12C4.601 23.687.743 8.735.013 3.013a1.858 1.858 0 0 1 .091-.835c.094-.269.248-.517.453-.727.205-.21.456-.378.736-.492.28-.114.583-.172.89-.17H7.01c.43 0 .851.12 1.207.344.357.224.632.541.792.91l1.09 2.52c.16.363.2.761.117 1.146a1.951 1.951 0 0 1-.588 1.014L7.39 8.79s1.289 8.057 9.944 9.054Z"
      fill="#fff"
    />
  </svg>
);

export const EmailIcon = props => (
  <svg
    width={28}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28 6.236V17.75c0 1.071-.46 2.098-1.281 2.856-.82.757-1.934 1.182-3.094 1.182H4.375c-1.16 0-2.273-.425-3.094-1.182C.461 19.847 0 18.82 0 17.748V6.236l13.556 7.363a.932.932 0 0 0 .889 0L28 6.236ZM23.625.787c1.076 0 2.115.366 2.918 1.029.802.662 1.312 1.575 1.43 2.562L14 11.966.026 4.378c.12-.987.63-1.9 1.431-2.562A4.589 4.589 0 0 1 4.375.787h19.25Z"
      fill="#fff"
    />
  </svg>
);
